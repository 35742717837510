import './style.css'
import logo from '../../assets/images/logo.png'

export function Header() {

    return <div className="container home-header">
        <div className="col-12 col-md-2 mt-2 mt-sm-0 h-100 justify-content-center flex-row d-flex align-items-center">
            <a href='/' className='logo justify-content-center flex-row d-flex align-items-center d-flex m-0 p-0'>
                <img className="bg-grey logo" src={logo} alt="logo" />
            </a>
        </div>
    </div>

}