import { useLayoutEffect, useRef } from "react"
import { useEffect, useState } from "react"
import "./style.css"

// Create auth context provider
export function BodyShapePlotBar({ label, sprite, amount, color }) {

    const [icons, setIcons] = useState([])
    const [iconNumber, setIconNumber] = useState(Math.ceil(amount / 4))
    const classes = "col ps-0 text-end highlighted" + " font-" + color
    const divRef = useRef();


    useEffect(() => {

        let tmp = []

        for (let i = 0; i < iconNumber; i++)
            tmp.push(<img className="me-2" src={sprite} />)

        setIcons(tmp)
    }, [amount])

    useLayoutEffect(() => {

        if (divRef !== null && divRef.current !== null && divRef.current.innerWidth !== null) {

            let width = divRef.current.clientWidth

            setIconNumber(Math.ceil(width / 20))
            console.log(Math.ceil(width / 20))
        
        }
    }, [divRef.current])

    return <div ref={divRef} className="col-12 mb-3">
        <div className="row age-bar d-flex align-items-center">
            <div className="col-auto h-100 age-bar-label">{label}</div>
            <div className={classes}>{amount}%</div>
        </div>
        <div className="row">
            <div className="col-12 age-bar-bar mt-2">
                <div className="age-bar-background px-0"></div>
                <div className={"h-100 age-bar-item bg-" + color} style={{ width: amount + '%' }}></div>
            </div>
        </div>
    </div>

}