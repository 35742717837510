import "./style.css"
import logo from '../../assets/images/logo.png'
import face from '../../assets/images/face.png'
import no_face from '../../assets/images/no_face.png'
import male from '../../assets/images/male.png'
import female from '../../assets/images/female.png'
import male_empty from '../../assets/images/male_empty.png'
import female_empty from '../../assets/images/female_empty.png'
import age1 from '../../assets/images/age1.png'
import age2 from '../../assets/images/age2.png'
import age3 from '../../assets/images/age3.png'
import age4 from '../../assets/images/age4.png'
import age5 from '../../assets/images/age5.png'
import age6 from '../../assets/images/age6.png'
import age7 from '../../assets/images/age7.png'
import User from '../../assets/images/User.png'
import body_red from '../../assets/images/body_red.png'
import body_yellow from '../../assets/images/body_yellow.png'
import body_blue from '../../assets/images/body_blue.png'
import left_arrow from '../../assets/images/left-arrow.png'
import Placeholder from '../../assets/images/logo_adidas.png'
import { Link, useNavigate, useParams } from "react-router-dom"
import { FirebaseImage } from "../../components/FirebaseImage/FirebaseImage"
import report from "./Report1.pdf"
import { useEffect, useLayoutEffect, useRef } from "react"
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5radar from "@amcharts/amcharts5/radar";
import { AgePlotBar } from "../../components/AgePlotBar/AgePlotBar"
import { Background } from "../../components/Background/Background"
import { BodyShapePlotBar } from "../../components/BodyShapePlotBar/BodyShapePlotBar"
import { StackedPlotBar } from "../../components/StackedPlotBar/StackedPlotBar"
import { useState } from "react"

export function ProfileDashboard() {

    const params = useParams()
    const profile_id = params.profile_id
    const agePerGenderRef = useRef()
    const bodyPerGenderRef = useRef()
    const radarRef = useRef()

    const [radarH, setRadarH] = useState(600)

    const gender_unit = 10

    const profiles = {
        "urban-shock": {
            "last_scan": "05/05/2022",
            "posts": 39,
            "index": 65.5,
            "logo": "280391605_1154503178734947_8569809424565034558_n.jpg"
        },
        "glamly": {
            "last_scan": "08/05/2022",
            "posts": 21,
            "index": 68.2,
            "logo": "280133234_714444373029327_1816612216657032107_n.jpg"
        },
        "bobbi-brown": {
            "last_scan": "05/05/2022",
            "posts": 39,
            "logo": "280391605_1154503178734947_8569809424565034558_n.jpg"
        }
    }

    const face_detection = {
        "glamly": {
            "face": 60,
            "no_face": 40
        },
        "urban-shock": {
            "face": 80,
            "no_face": 20
        },
        "bobbi-brown": {
            "face": 43,
            "no_face": 57
        }
    }

    const gender_detection = {
        "glamly": {
            "male": 40,
            "female": 60
        },
        "urban-shock": {
            "male": 30,
            "female": 70
        },
        "bobbi-brown": {
            "male": 1,
            "female": 99
        }
    }

    const ages_dict = {
        "glamly": {
            "Childhood (0-10)": {
                "value": 0,
                "color": "yellow",
                "icon": age1
            },
            "Adolescence (10-18)": {
                "value": 8,
                "color": "red",
                "icon": age2
            },
            "Young adult (18-25)": {
                "value": 67,
                "color": "pink",
                "icon": age3
            },
            "Early adulthood (25-40)": {
                "value": 25,
                "color": "blue",
                "icon": age4
            },
            "Middle adulthood (40-65)": {
                "value": 0,
                "color": "green",
                "icon": age5
            },
            "Advanced adulthood (65-75)": {
                "value": 0,
                "color": "orange",
                "icon": age6
            },
            "Late adulthood (75+)": {
                "value": 0,
                "color": "light",
                "icon": age7
            }
        },
        "urban-shock": {
            "Childhood (0-10)": {
                "value": 0,
                "color": "yellow",
                "icon": age1
            },
            "Adolescence (10-18)": {
                "value": 38,
                "color": "red",
                "icon": age2
            },
            "Young adult (18-25)": {
                "value": 44,
                "color": "pink",
                "icon": age3
            },
            "Early adulthood (25-40)": {
                "value": 19,
                "color": "blue",
                "icon": age4
            },
            "Middle adulthood (40-65)": {
                "value": 0,
                "color": "green",
                "icon": age5
            },
            "Advanced adulthood (65-75)": {
                "value": 0,
                "color": "orange",
                "icon": age6
            },
            "Late adulthood (75+)": {
                "value": 0,
                "color": "light",
                "icon": age7
            }
        },
        "bobbi-brown": {
            "Childhood (0-10)": {
                "value": 0,
                "color": "yellow",
                "icon": age1
            },
            "Adolescence (10-18)": {
                "value": 35,
                "color": "red",
                "icon": age2
            },
            "Young adult (18-25)": {
                "value": 48,
                "color": "pink",
                "icon": age3
            },
            "Early adulthood (25-40)": {
                "value": 16,
                "color": "blue",
                "icon": age4
            },
            "Middle adulthood (40-65)": {
                "value": 1,
                "color": "green",
                "icon": age5
            },
            "Advanced adulthood (65-75)": {
                "value": 0,
                "color": "orange",
                "icon": age6
            },
            "Late adulthood (75+)": {
                "value": 0,
                "color": "light",
                "icon": age7
            }
        }
    }

    const ethnicity_dict = {
        "glamly": {
            "Middle Eastern": {
                "color": "blue",
                "value": 0,
                "icon_tag": "Middle  Eastern"
            },
            "Asian": {
                "color": "green",
                "value": 17,
                "icon_tag": "Asian"
            },
            "Latino/Hispanic": {
                "color": "pink",
                "value": 8,
                "icon_tag": "Hispanic"
            },
            "Black": {
                "color": "orange",
                "value": 34,
                "icon_tag": "Black"
            },
            "White": {
                "color": "red",
                "value": 33,
                "icon_tag": "White"
            },
            "Indian": {
                "color": "yellow",
                "value": 8,
                "icon_tag": "Indian"
            }
        },
        "urban-shock": {
            "Middle Eastern": {
                "color": "blue",
                "value": 0,
                "icon_tag": "Middle  Eastern"
            },
            "Asian": {
                "color": "green",
                "value": 16,
                "icon_tag": "Asian"
            },
            "Latino/Hispanic": {
                "color": "pink",
                "value": 16,
                "icon_tag": "Hispanic"
            },
            "Black": {
                "color": "orange",
                "value": 16,
                "icon_tag": "Black"
            },
            "White": {
                "color": "red",
                "value": 17,
                "icon_tag": "White"
            },
            "Indian": {
                "color": "yellow",
                "value": 0,
                "icon_tag": "Indian"
            }
        },
        "bobbi-brown": {
            "Middle Eastern": {
                "color": "blue",
                "value": 3,
                "icon_tag": "Middle  Eastern"
            },
            "Asian": {
                "color": "green",
                "value": 8,
                "icon_tag": "Asian"
            },
            "Latino/Hispanic": {
                "color": "pink",
                "value": 14,
                "icon_tag": "Hispanic"
            },
            "Black": {
                "color": "orange",
                "value": 26,
                "icon_tag": "Black"
            },
            "White": {
                "color": "red",
                "value": 47,
                "icon_tag": "White"
            },
            "Indian": {
                "color": "yellow",
                "value": 2,
                "icon_tag": "Indian"
            }
        }
    }

    const body_dict = {
        "glamly": {
            "Underweight": {
                "value": 16,
                "color": "red",
                "icon": body_red
            },
            "Normal": {
                "value": 76,
                "color": "blue",
                "icon": body_blue
            },
            "Overweight": {
                "value": 8,
                "color": "orange",
                "icon": body_yellow
            }
        },
        "urban-shock": {
            "Underweight": {
                "value": 13,
                "color": "red",
                "icon": body_red
            },
            "Normal": {
                "value": 75,
                "color": "blue",
                "icon": body_blue
            },
            "Overweight": {
                "value": 13,
                "color": "orange",
                "icon": body_yellow
            }
        },
        "bobbi-brown": {
            "Underweight": {
                "value": 4,
                "color": "red",
                "icon": body_red
            },
            "Normal": {
                "value": 95,
                "color": "blue",
                "icon": body_blue
            },
            "Overweight": {
                "value": 1,
                "color": "orange",
                "icon": body_yellow
            }
        }
    }

    const ages_per_gender_dict = {
        "glamly": {
            "Childhood (0-10)": {
                "male": {
                    "value": 0,
                    "icon": male
                },
                "female": {
                    "value": 0,
                    "icon": female
                }
            },
            "Adolescence (10-18)": {
                "male": {
                    "value": 0,
                    "icon": male
                },
                "female": {
                    "value": 8,
                    "icon": female
                }
            },
            "Young adult (18-25)": {
                "male": {
                    "value": 22,
                    "icon": male
                },
                "female": {
                    "value": 45,
                    "icon": female
                }
            },
            "Early adulthood (25-40)": {
                "male": {
                    "value": 17,
                    "icon": male
                },
                "female": {
                    "value": 8,
                    "icon": female
                }
            },
            "Middle adulthood (40-65)": {
                "male": {
                    "value": 0,
                    "icon": male
                },
                "female": {
                    "value": 0,
                    "icon": female
                }
            },
            "Advanced adulthood (65-75)": {
                "male": {
                    "value": 0,
                    "icon": male
                },
                "female": {
                    "value": 0,
                    "icon": female
                }
            },
            "Late adulthood (75+)": {
                "male": {
                    "value": 0,
                    "icon": male
                },
                "female": {
                    "value": 0,
                    "icon": female
                }
            }
        },
        "urban-shock": {
            "Childhood (0-10)": {
                "male": {
                    "value": 0,
                    "icon": male
                },
                "female": {
                    "value": 0,
                    "icon": female
                }
            },
            "Adolescence (10-18)": {
                "male": {
                    "value": 13,
                    "icon": male
                },
                "female": {
                    "value": 25,
                    "icon": female
                }
            },
            "Young adult (18-25)": {
                "male": {
                    "value": 15,
                    "icon": male
                },
                "female": {
                    "value": 29,
                    "icon": female
                }
            },
            "Early adulthood (25-40)": {
                "male": {
                    "value": 6,
                    "icon": male
                },
                "female": {
                    "value": 13,
                    "icon": female
                }
            },
            "Middle adulthood (40-65)": {
                "male": {
                    "value": 0,
                    "icon": male
                },
                "female": {
                    "value": 0,
                    "icon": female
                }
            },
            "Advanced adulthood (65-75)": {
                "male": {
                    "value": 0,
                    "icon": male
                },
                "female": {
                    "value": 0,
                    "icon": female
                }
            },
            "Late adulthood (75+)": {
                "male": {
                    "value": 0,
                    "icon": male
                },
                "female": {
                    "value": 0,
                    "icon": female
                }
            }
        },
        "bobbi-brown": {
            "Childhood (0-10)": {
                "male": {
                    "value": 0,
                    "icon": male
                },
                "female": {
                    "value": 0,
                    "icon": female
                }
            },
            "Adolescence (10-18)": {
                "male": {
                    "value": 0,
                    "icon": male
                },
                "female": {
                    "value": 35,
                    "icon": female
                }
            },
            "Young adult (18-25)": {
                "male": {
                    "value": 1,
                    "icon": male
                },
                "female": {
                    "value": 47,
                    "icon": female
                }
            },
            "Early adulthood (25-40)": {
                "male": {
                    "value": 0,
                    "icon": male
                },
                "female": {
                    "value": 16,
                    "icon": female
                }
            },
            "Middle adulthood (40-65)": {
                "male": {
                    "value": 1,
                    "icon": male
                },
                "female": {
                    "value": 1,
                    "icon": female
                }
            },
            "Advanced adulthood (65-75)": {
                "male": {
                    "value": 0,
                    "icon": male
                },
                "female": {
                    "value": 0,
                    "icon": female
                }
            },
            "Late adulthood (75+)": {
                "male": {
                    "value": 0,
                    "icon": male
                },
                "female": {
                    "value": 0,
                    "icon": female
                }
            }
        }
    }

    const ethnicity_per_gender_dict = {
        "glamly": {
            "Middle Eastern": {
                "male": {
                    "value": 0,
                    "icon": male
                },
                "female": {
                    "value": 0,
                    "icon": female
                }
            },
            "Asian": {
                "male": {
                    "value": 0,
                    "icon": male
                },
                "female": {
                    "value": 17,
                    "icon": female
                }
            },
            "Latino/Hispanic": {
                "male": {
                    "value": 0,
                    "icon": male
                },
                "female": {
                    "value": 8,
                    "icon": female
                }
            },
            "Black": {
                "male": {
                    "value": 24,
                    "icon": male
                },
                "female": {
                    "value": 10,
                    "icon": female
                }
            },
            "White": {
                "male": {
                    "value": 16,
                    "icon": male
                },
                "female": {
                    "value": 17,
                    "icon": female
                }
            },
            "Indian": {
                "male": {
                    "value": 0,
                    "icon": male
                },
                "female": {
                    "value": 8,
                    "icon": female
                }
            }
        },
        "urban-shock": {
            "Middle Eastern": {
                "male": {
                    "value": 0,
                    "icon": male
                },
                "female": {
                    "value": 0,
                    "icon": female
                }
            },
            "Asian": {
                "male": {
                    "value": 5,
                    "icon": male
                },
                "female": {
                    "value": 11,
                    "icon": female
                }
            },
            "Latino/Hispanic": {
                "male": {
                    "value": 7,
                    "icon": male
                },
                "female": {
                    "value": 9,
                    "icon": female
                }
            },
            "Black": {
                "male": {
                    "value": 9,
                    "icon": male
                },
                "female": {
                    "value": 7,
                    "icon": female
                }
            },
            "White": {
                "male": {
                    "value": 16,
                    "icon": male
                },
                "female": {
                    "value": 37,
                    "icon": female
                }
            },
            "Indian": {
                "male": {
                    "value": 0,
                    "icon": male
                },
                "female": {
                    "value": 0,
                    "icon": female
                }
            }
        },
        "bobbi-brown": {
            "Middle Eastern": {
                "male": {
                    "value": 0,
                    "icon": male
                },
                "female": {
                    "value": 3,
                    "icon": female
                }
            },
            "Asian": {
                "male": {
                    "value": 5,
                    "icon": male
                },
                "female": {
                    "value": 8,
                    "icon": female
                }
            },
            "Latino/Hispanic": {
                "male": {
                    "value": 0,
                    "icon": male
                },
                "female": {
                    "value": 14,
                    "icon": female
                }
            },
            "Black": {
                "male": {
                    "value": 1,
                    "icon": male
                },
                "female": {
                    "value": 25,
                    "icon": female
                }
            },
            "White": {
                "male": {
                    "value": 1,
                    "icon": male
                },
                "female": {
                    "value": 46,
                    "icon": female
                }
            },
            "Indian": {
                "male": {
                    "value": 0,
                    "icon": male
                },
                "female": {
                    "value": 2,
                    "icon": female
                }
            }
        }
    }

    const body_per_gender_dict = {
        "glamly": {
            "Underweight": {
                "male": {
                    "value": 0,
                    "icon": male
                },
                "female": {
                    "value": 16,
                    "icon": female
                }
            },
            "Normal": {
                "male": {
                    "value": 36,
                    "icon": male
                },
                "female": {
                    "value": 40,
                    "icon": female
                }
            },
            "Overweight": {
                "male": {
                    "value": 8,
                    "icon": male
                },
                "female": {
                    "value": 0,
                    "icon": female
                }
            }
        },
        "urban-shock": {
            "Underweight": {
                "male": {
                    "value": 0,
                    "icon": male
                },
                "female": {
                    "value": 13,
                    "icon": female
                }
            },
            "Normal": {
                "male": {
                    "value": 25,
                    "icon": male
                },
                "female": {
                    "value": 50,
                    "icon": female
                }
            },
            "Overweight": {
                "male": {
                    "value": 13,
                    "icon": male
                },
                "female": {
                    "value": 0,
                    "icon": female
                }
            }
        },
        "bobbi-brown": {
            "Underweight": {
                "male": {
                    "value": 1,
                    "icon": male
                },
                "female": {
                    "value": 4,
                    "icon": female
                }
            },
            "Normal": {
                "male": {
                    "value": 1,
                    "icon": male
                },
                "female": {
                    "value": 94,
                    "icon": female
                }
            },
            "Overweight": {
                "male": {
                    "value": 1,
                    "icon": male
                },
                "female": {
                    "value": 1,
                    "icon": female
                }
            }
        }
    }

    function getFaces() {
        let f = face_detection[profile_id].face - (face_detection[profile_id].face % 5)
        let nf = face_detection[profile_id].no_face - (face_detection[profile_id].no_face % 5)

        return <>
            <div className="col-6">
                <div className="wrapper">
                    <FirebaseImage horizontal={false} path={"template/" + f + "% green.png"} />
                </div>
                <div className="plot-label text-center mt-2 font-light px-4">
                    <span className="highlighted font-green">{face_detection[profile_id].face}%</span> of images
                    contain at least one face
                </div>
            </div>
            <div className="col-6">
                <div className="wrapper">
                    <FirebaseImage horizontal={false} path={"template/" + nf + "% red.png"} />
                </div>
                <div className="plot-label text-center mt-2 font-light px-4">
                    <span className="highlighted font-red">{face_detection[profile_id].no_face}%</span> of images
                    contain no face
                </div>
            </div>
        </>
    }

    function getAgePerGender() {
        /* const bars = []

        Object.keys(ages_per_gender_dict[profile_id]).map((key, index) => {
            const classes = "col-1 text-start highlighted" + " font-" + ages_per_gender_dict[profile_id][key]['color']

            const icon_number_m = Math.ceil((ages_per_gender_dict[profile_id][key]['male']['value'] / 4))
            const icon_number_f = Math.ceil((ages_per_gender_dict[profile_id][key]['female']['value'] / 4))
            const icons1 = []
            const icons2 = []

            icons1.push(<div className="w-100 text-end font-blue bold">{ages_per_gender_dict[profile_id][key]['male']['value']}%</div>)
            icons2.push(<div className="w-100 text-start font-pink bold">{ages_per_gender_dict[profile_id][key]['female']['value']}%</div>)
            for (let i = 0; i < icon_number_m; i++)
                icons1.push(<img className="mt-2" src={male} />)

            for (let i = 0; i < icon_number_f; i++)
                icons2.push(<img className="mt-2" src={female} />)

            var bar = <div className="col mb-3">
                <div className="row h-100">
                    <div className="col-12">
                        <div className="row h-100">
                            <div className="col d-flex flex-column align-items-end justify-content-end">
                                {icons1}
                            </div>

                            <div className="col d-flex flex-column align-items-start justify-content-end">
                                {icons2}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            bars.push(bar)
        })

        return <><div className="row">{bars}</div><div className="row">
            {Object.keys(ages_per_gender_dict[profile_id]).map((key, index) => {
                return <div className="col text-center font-small">{key.split(' ').slice(0, key.split(' ').length - 1).join(' ')}<br />{key.split(' ')[key.split(' ').length - 1]}</div>
            })}
        </div></> */
        const bars = []

        Object.keys(ages_per_gender_dict[profile_id]).map((key, index) => {
            bars.push(<StackedPlotBar items={Object.keys(ages_per_gender_dict[profile_id]).length} width={agePerGenderRef} label={key} amount1={ages_per_gender_dict[profile_id][key]['female']['value']} amount2={ages_per_gender_dict[profile_id][key]['male']['value']} color1={"red"} color2={"orange"} />)
        })

        return bars
    }

    function getEthnicityPerGender() {
        const bars = []

        Object.keys(ethnicity_per_gender_dict[profile_id]).map((key, index) => {
            bars.push(<StackedPlotBar items={Object.keys(ethnicity_per_gender_dict[profile_id]).length} width={bodyPerGenderRef} label={key} amount1={ethnicity_per_gender_dict[profile_id][key]['female']['value']} amount2={ethnicity_per_gender_dict[profile_id][key]['male']['value']} color1={"red"} color2={"orange"} />)
        })

        return bars
    }

    function getBodyPerGender() {
        const bars = []

        Object.keys(body_per_gender_dict[profile_id]).map((key, index) => {
            bars.push(<StackedPlotBar items={Object.keys(body_per_gender_dict[profile_id]).length} width={bodyPerGenderRef} label={key} amount1={body_per_gender_dict[profile_id][key]['female']['value']} amount2={body_per_gender_dict[profile_id][key]['male']['value']} color1={"red"} color2={"orange"} />)
        })

        return bars
    }

    function getBodyShape() {
        const bars = []

        Object.keys(body_dict[profile_id]).map((key, index) => {
            /* const icon_number = Math.ceil((body_dict[profile_id][key]['value'] / 3))
            const icons = []
            for (let i = 0; i < icon_number; i++)
                icons.push(<img className="me-2" src={body_dict[profile_id][key]['icon']} />)

            var bar = <div className="col-12 mb-3">
                <div className="row d-flex align-items-center">
                    <div className="col-2 text-end">{key}</div>
                    <div className="col-10">{icons}</div>
                </div>
            </div>
            bars.push(bar) */

            bars.push(<BodyShapePlotBar label={key} amount={body_dict[profile_id][key]['value']} color={body_dict[profile_id][key]['color']} />)
        })

        /*        const last_row = []
               Object.keys(body_dict[profile_id]).map((key, index) => {
       
                   const classes = "highlighted me-2 font-" + body_dict[profile_id][key]['color']
                   last_row.push(<div className="col-auto text-start mx-4 font-light"><span className={classes}>{body_dict[profile_id][key]['value']}%</span>{key}</div>)
               })
               bars.push(<div className="col-12 mt-4 mb-3"><div className="row d-flex flex-row justify-content-center">{last_row}</div></div>)
        */
        return bars
    }

    function getEthnicity() {
        const bars = []

        Object.keys(ethnicity_dict[profile_id]).map((key, index) => {
            const classes = "indicator-value col-2 text-center highlighted" + " font-" + ethnicity_dict[profile_id][key]['color']

            const casted_value = ethnicity_dict[profile_id][key]['value'] - (ethnicity_dict[profile_id][key]['value'] % 5)
            const icon_number = Math.ceil((ethnicity_dict[profile_id][key]['value'] / 2))
            const icons = <FirebaseImage horizontal={false} path={"template/" + ethnicity_dict[profile_id][key]['icon_tag'] + " - " + casted_value + ".png"} />

            var bar = <div className="col-12 col-sm-4 mb-4 mb-sm-3">
                <div className="row ethnicity-block">
                    <div className="indicator">{icons}</div>
                    <div className={classes}>{ethnicity_dict[profile_id][key]['value']}%</div>
                </div>
                <div className="row">
                    <div className="col-12 text-center">{key}</div>
                </div>
            </div>
            bars.push(bar)
        })

        return bars
    }

    function getAge() {
        const bars = []

        Object.keys(ages_dict[profile_id]).map((key, index) => {
            bars.push(<AgePlotBar label={key} amount={ages_dict[profile_id][key]['value']} color={ages_dict[profile_id][key]['color']} />)
        })

        return bars
    }

    function getGender(gender) {
        const full = gender === "male" ? male : female
        const empty = gender === "male" ? male_empty : female_empty
        const value = gender === "male" ? gender_detection[profile_id].male : gender_detection[profile_id].female
        const steps = []
        let first_group = Math.ceil((value / gender_unit))

        for (let i = 0; i < 10; i++) {
            if (i < first_group)
                steps.push(<img src={full} />)
            else
                steps.push(<img src={empty} />);
        }

        return <div className="row">
            <div className="col-12 d-flex justify-content-between">{steps}</div>
        </div>
    }

    useEffect(() => {
        /* Chart code */
        // Create root element
        // https://www.amcharts.com/docs/v5/getting-started/#Root_element
        let root = am5.Root.new("chartdiv");

        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        // Create chart
        // https://www.amcharts.com/docs/v5/charts/radar-chart/
        let chart = root.container.children.push(am5radar.RadarChart.new(root, {
            panX: false,
            panY: false,
            wheelX: "none",
            wheelY: "none"
        }));

        // Add cursor
        // https://www.amcharts.com/docs/v5/charts/radar-chart/#Cursor
        let cursor = chart.set("cursor", am5radar.RadarCursor.new(root, {
            behavior: "zoomX"
        }));

        cursor.lineY.set("visible", false);

        // Create axes and their renderers
        // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_axes
        let xRenderer = am5radar.AxisRendererCircular.new(root, {});
        xRenderer.labels.template.setAll({
            radius: 10
        });

        let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
            maxDeviation: 0,
            categoryField: "country",
            renderer: xRenderer,
            //tooltip: am5.Tooltip.new(root, {})
        }));

        let yRenderer = am5radar.AxisRendererRadial.new(root, {})
        yRenderer.labels.template.setAll({})

        let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            renderer: yRenderer,
            max: 100,
            min: 0
        }));

        let series2 = chart.series.push(am5radar.RadarLineSeries.new(root, {
            name: "Series",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "avg",
            categoryXField: "country",
            fill: am5.color(0x04A777),
            stroke: am5.color(0x04A777),
            tooltip: null,
            zIndex: 0
        }));

        series2.strokes.template.setAll({
            strokeWidth: 2,
            stroke: am5.color(0xDEF6CA),
            fill: am5.color(0xDEF6CA)
        });

        series2.bullets.push(function () {
            return am5.Bullet.new(root, {
                sprite: am5.Circle.new(root, {
                    radius: 5,
                    fill: series2.get("fill")
                })
            });
        });

        // Create series
        // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_series
        let series = chart.series.push(am5radar.RadarLineSeries.new(root, {
            name: "Series",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "litres",
            categoryXField: "country",
            fill: am5.color(0x226A84),
            stroke: am5.color(0x226A84),
            tooltip: null,
            zIndex: 100
        }));

        series.strokes.template.setAll({
            strokeWidth: 2,
            stroke: am5.color(0xAEE4F1),
            zIndex: 100
        });

        series.bullets.push(function () {
            return am5.Bullet.new(root, {
                sprite: am5.Circle.new(root, {
                    radius: 5,
                    fill: series.get("fill")
                })
            });
        });

        // Set data
        // https://www.amcharts.com/docs/v5/charts/radar-chart/#Setting_data
        let data = [{
            "country": "age",
            "litres": 51,
            "avg": 80
        }, {
            "country": "gender",
            "litres": 89,
            "avg": 80
        }, {
            "country": "ethnicity",
            "litres": 60,
            "avg": 80
        }, {
            "country": "body shape",
            "litres": 5,
            "avg": 60
        }, {
            "country": "age per gender",
            "litres": 87.5,
            "avg": 60
        }, {
            "country": "ethnicity per gender",
            "litres": 92.4,
            "avg": 60
        }, {
            "country": "body shape per gender",
            "litres": 84,
            "avg": 40
        }];

        series2.data.setAll(data);
        series.data.setAll(data);

        xAxis.data.setAll(data);


        // Animate chart and series in
        // https://www.amcharts.com/docs/v5/concepts/animations/#Initial_animation
        series2.appear(1000);
        series.appear(1000);
        chart.seriesContainer.zIndex = -1

        chart.appear(1000, 100);

        return () => {
            root.dispose();
        };

    }, [])

    useEffect(() => {
        
        if (radarRef !== null && radarRef.current !== null && radarRef.current.clientWidth !== null && radarRef.current.clientHeight !== null) {
            setRadarH(radarRef.current.clientWidth > radarRef.current.clientHeight ? radarRef.current.clientHeight : radarRef.current.clientWidth)
        }

    }, [radarRef])

    return <div className="container-fluid dashboard profile p-0">

        <Background />

        {/* HOME PAGE HEADER */}
        <div className="container header">
            <div className="row dashboard-header">
                <div className="col-sm-2 col-6 h-100 justify-content-start flex-row d-flex align-items-center">
                    <Link className="logo" to="/"><img src={logo} className="img-fluid" alt="logo" /></Link>
                </div>
                <div className="col-6 col-sm-10 justify-content-end flex-row d-flex align-items-center">
                    <div className="ps-5 menu-item d-flex flex-row align-items-end mt-3"><img src={User} /></div>
                </div>
            </div>
        </div>

        {/* ACCOUNT LISTS */}
        <div className="container block-2 pb-5 mt-5">

            <div className="row d-flex align-items-center">
                <div className="col-12 col-sm-4 d-none d-sm-flex justify-content-start">
                    <div className="title text-center d-flex align-items-center flex-row">
                        <Link to="/dashboard"><img className="small-icon me-2" src={left_arrow} /><span>All accounts</span></Link>
                    </div>
                </div>

                <div className="col-12 col-sm-4">
                    <div className="row main-title text-center profile-title d-sm-flex flex-row justify-content-center align-items-center">
                        <div className="col-sm-auto col-12 logo ps-0">
                            <FirebaseImage horizontal={false} path={profiles[profile_id].logo} />
                        </div>
                        <div className="col-sm-auto col-12 bg-grey">
                            {profile_id}
                        </div>
                    </div>
                </div>

                <div className="col-12 col-sm-4 mt-3 mt-sm-0 bg-grey d-block d-sm-flex justify-content-end">
                    <div className="title text-center">
                        <a href={report} download="report_v1.pdf"><button className="btn btn-small-outline-dandi">
                            Download the report
                        </button></a>
                    </div>
                </div>
            </div>

            <div className="text-center bg-grey font-light mt-3">
                Source data’s update on the {profiles[profile_id].last_scan}
            </div>

            {/* Diversity and Inclusion index */}
            <div className="row mt-5 d-flex justify-content-center">

                <div className="col-12 col-sm-auto">
                    <div className="dandi-card p-4">
                        <div className="row">
                            <div className="col-12 title text-center">DandI Score</div>
                            <div className="col-12 text-center mt-2"><span className="highlighted font-blue">{profiles[profile_id].index}%</span></div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="row mt-4 mt-sm-5 equal">

                {/* FACE DETECTION */}
                <div className="col-sm-6 col-12">
                    <div className="dandi-card p-4 h-100">

                        {/* Block header */}
                        <div className="row">
                            <div className="col-12 title">
                                Face Detection
                            </div>
                            <div className="col-12 font-light">
                                % of images with people
                            </div>
                        </div>

                        {/* Legend */}
                        <div className="row mt-3">
                            <div className="col-auto font-light align-items-center d-flex">
                                <img src={face} className="me-2" /> Face
                            </div>
                            <div className="col-auto font-light align-items-center d-flex">
                                <img src={no_face} className="me-2" /> No face
                            </div>
                        </div>

                        {/* Second row */}
                        <div className="row mt-5 face-plot">
                            {getFaces()}
                        </div>
                    </div>
                </div>

                {/* GENDER DETECTION */}
                <div className="col-sm-6 col-12 mt-4 mt-sm-0">
                    <div className="dandi-card p-4 h-100">

                        {/* Block header */}
                        <div className="row">
                            <div className="col-12 title">
                                Gender
                            </div>
                            <div className="col-12 font-light">
                                % of males and females
                            </div>
                        </div>

                        {/* Legend */}
                        <div className="row mt-3">
                            <div className="col-auto font-light align-items-center d-flex">
                                <img src={male} className="me-2" /> Male
                            </div>
                            <div className="col-auto font-light align-items-center d-flex">
                                <img src={female} className="me-2" /> Female
                            </div>
                        </div>

                        {/* Second row */}
                        <div className="row mt-5 face-plot">
                            <div className="col-12">
                                <div className="row d-flex align-items-center flex-row">
                                    <div className="col-12 col-sm-9">
                                        {getGender("male")}
                                    </div>
                                    <div className="col-12 col-sm-3 mt-2 mt-sm-0 d-flex justify-content-center d-sm-block">
                                        <div className="plot-label font-light">
                                            <span className="highlighted font-blue">{gender_detection[profile_id].male}%</span> male
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex align-items-center flex-row mt-sm-5 mt-4">
                                    <div className="col-12 col-sm-9">
                                        {getGender("female")}
                                    </div>
                                    <div className="col-12 col-sm-3 mt-2 mt-sm-0 d-flex justify-content-center d-sm-block">
                                        <div className="plot-label font-light">
                                            <span className="highlighted font-pink">{gender_detection[profile_id].female}%</span> female
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

            <div className="row mt-4 equal">

                {/* AGE */}
                <div className="col-12">
                    <div className="dandi-card p-4 h-100">

                        {/* Block header */}
                        <div className="row">
                            <div className="col-12 title">
                                Age
                            </div>
                            <div className="col-12 font-light">
                                Distribution over generation life stages
                            </div>
                        </div>

                        {/* Second row */}
                        <div className="row mt-5 face-plot">
                            {getAge()}
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-4 equal">

                {/* ETHINICTY */}
                <div className="col-12">
                    <div className="dandi-card p-4 h-100">

                        {/* Block header */}
                        <div className="row">
                            <div className="col-12 title">
                                Ethnicity
                            </div>
                            <div className="col-12 font-light">
                                Distribution over different cultures
                            </div>
                        </div>

                        {/* Second row */}
                        <div className="row mt-5 face-plot">
                            {getEthnicity()}
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-4 equal">

                {/* BODY DIVERSITY */}
                <div className="col-12">
                    <div className="dandi-card p-4 h-100">

                        {/* Block header */}
                        <div className="row">
                            <div className="col-12 title">
                                Body Diversity
                            </div>
                            <div className="col-12 font-light">
                                Distribution over body types
                            </div>
                        </div>

                        {/* Second row */}
                        <div className="row mt-5 face-plot">
                            {getBodyShape()}
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-5">
                <div className="col-12 main-title text-center">
                    Data analysis by gender distribution
                </div>
            </div>

            <div className="row mt-4 equal">

                <div className="col-12">

                    {/* AGE PER GENDER DISTRIBUTION */}
                    <div className="dandi-card p-4 h-100">
                        {/* Block header */}
                        <div className="row mb-2">
                            <div className="col-12 title">
                                Age per Gender Distribution
                            </div>
                        </div>

                        {/* Legend */}
                        <div className="row mt-3 mb-5">
                            <div className="col-auto font-light align-items-center d-flex">
                                <img src={male} className="me-2" /> Male
                            </div>
                            <div className="col-auto font-light align-items-center d-flex">
                                <img src={female} className="me-2" /> Female
                            </div>
                        </div>

                        <div ref={agePerGenderRef} className="row">
                            {getAgePerGender()}
                        </div>
                    </div>

                </div>
            </div>

            <div className="row mt-4 equal">

                <div className="col-12 col-sm-6">

                    {/* ETHNICITY PER GENDER DISTRIBUTION */}
                    <div className="dandi-card p-4 h-100">
                        {/* Block header */}
                        <div className="row mb-2">
                            <div className="col-12 title">
                                Ethnicity per Gender Distribution
                            </div>
                        </div>

                        {/* Legend */}
                        <div className="row mt-3 mb-5">
                            <div className="col-auto font-light align-items-center d-flex">
                                <img src={male} className="me-2" /> Male
                            </div>
                            <div className="col-auto font-light align-items-center d-flex">
                                <img src={female} className="me-2" /> Female
                            </div>
                        </div>

                        <div className="row">
                            {getEthnicityPerGender()}
                        </div>
                    </div>

                </div>

                <div className="col-12 col-sm-6">

                    {/* BODY PER GENDER DISTRIBUTION */}
                    <div className="dandi-card p-4 h-100">

                        {/* Block header */}
                        <div className="row mb-2">
                            <div className="col-12 title">
                                Body shape per Gender Distribution
                            </div>
                        </div>

                        {/* Legend */}
                        <div className="row mt-3 mb-5">
                            <div className="col-auto font-light align-items-center d-flex">
                                <img src={male} className="me-2" /> Male
                            </div>
                            <div className="col-auto font-light align-items-center d-flex">
                                <img src={female} className="me-2" /> Female
                            </div>
                        </div>

                        <div ref={bodyPerGenderRef} className="row">
                            {getBodyPerGender()}
                        </div>
                    </div>

                </div>

            </div>

            <div className="row mt-4">

                <div className="col-12">

                    {/* BODY PER GENDER DISTRIBUTION */}
                    <div className="dandi-card p-4 h-sm-100">

                        {/* Block header */}
                        <div className="row mb-2">
                            <div className="col-12 title">
                                Visual Divesity and Inclusion radar chart
                            </div>
                            <div className="col-12 font-light">
                                Maps the dimensions of diversity and inclusion with respect to the current measured level.
                            </div>
                        </div>

                        {radarH !== null && <div style={{ height: radarH }} ref={radarRef} id="chartdiv"></div>}
                    </div>
                </div>
            </div>
        </div>

        {/* BACKGROUND BLOCK */}
        <div className="container-fluid footer pt-3">
            <div className="row mb-3">
                <div className="col-12 text-center">
                    <img src={logo} className="img-fluid" alt="logo" />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12 text-center">
                    © 2022 – DandI.ai
                </div>
            </div>
        </div>

    </div>
}