import "./style.css"
import logo from '../../assets/images/logo.png'
import benefits_1 from '../../assets/images/benefits-1.png'
import benefits_2 from '../../assets/images/benefits-2.png'
import benefits_3 from '../../assets/images/benefits-3.png'
import benefits_4 from '../../assets/images/benefits-4.png'
import home_image_1 from '../../assets/images/home_image_1.png'
import { useNavigate, useSearchParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { useData } from "../../components/DataProvider/DataProvider"
import { Background } from "../../components/Background/Background"
import { Footer } from "../../components/Footer/Footer"
import { BaseTemplate } from "../../components/BaseTemplate"
import axios from "axios"

export function AddNewProfile() {

    const navigate = useNavigate()
    const [params, setSearchParams] = useSearchParams();

    const [loginVisible, setLoginVisible] = useState(false)
    const [demoPopupVisible, setDemoPopupVisible] = useState(false)
    const [loginClasses, setLoginClasses] = useState("")
    const [demoPopupClasses, setDemoPopupClasses] = useState("")
    const { data, updateData, isAuthenticated } = useData()

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    async function login() {
        updateData("login", {
            "username": username,
            "password": password
        }, () => navigate("/dashboard"))
    }

    function handleDemoPopup() {
        setDemoPopupVisible(true)
    }

    /* useEffect(() => {

        axios.post("https://api.dandi.digital/add_new_access_token", {
            code: params.get("code"),
            username: localStorage.getItem("username"),
            token: localStorage.getItem("token")
        }).then((response) => {
            console.log(response)
            navigate("/dashboard")
        }).catch((error) => {
            console.log(error)
        })

    }, []) */

    return <BaseTemplate center={true}>

        {/* Spinner */}
        <div className="row">
            <div className="col-12 justify-content-center d-flex flex-column align-items-center text-center">
                <div className="spinner-container">
                    <div className="spinner-border text-dark" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
                <br></br>
                We are loading your Instagram profile...<br></br>
                You will be redirect to the dashboard in a few seconds.
            </div>
        </div>

    </BaseTemplate>
}