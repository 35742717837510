import "./style.css"
import logo from '../../assets/images/logo.png'
import dots_menu from '../../assets/images/more.png'
import { Link, useNavigate } from "react-router-dom"
import User from '../../assets/images/User.png'
import { FirebaseImage } from "../../components/FirebaseImage/FirebaseImage"
import { Background } from "../../components/Background/Background"
import { useData } from "../../components/DataProvider/DataProvider"
import { useState } from "react"
import { BaseTemplate } from "../../components/BaseTemplate"

export function Dashboard() {

    // Import data context
    let { data, updateData } = useData()

    // Popup management variables
    let [popupVisible, setPopupVisible] = useState(false)
    let [newProfile, setNewProfile] = useState(null)

    /**
     * Invoke the method to write the new profile in Firestore.
     */
    function addNewProfile() {
        // Open new tab
        window.open("https://api.instagram.com/oauth/authorize?client_id=861854614986202&redirect_uri=https://www.dandi.digital/new_profile/&scope=user_profile,user_media&response_type=code")
    }

    return <BaseTemplate>

        {/* POPUP */}
        {popupVisible === true && <div className="p-0 m-0 w-100 h-100 popup-container d-flex flex-row justify-content-center align-items-center">
            <div className="popup p-5 m-3 m-sm-0">
                <div className="row text-center">
                    <h4>Connect a new account</h4>
                </div>
                <div className="row">
                    <label className="px-0 mt-3">Username</label>
                    <input type="text" onChange={(e) => setNewProfile(e.target.value)} className="mt-1 text-center" />
                </div>
                <div className="row mt-5 mt-sm-4 d-flex justify-content-center">
                    <button className="btn btn-outline-dandi col-12 col-sm-4" onClick={() => setPopupVisible(false)}>Cancel</button>
                    <button className="btn btn-dandi col-12 col-sm-4 ms-sm-2 mt-2 mt-sm-0" onClick={() => addNewProfile()}>Add</button>
                </div>
            </div>
        </div>}
        {/* END POPUP */}

        {/* ACCOUNT LISTS */}
        <div className="dashboard container block-2 py-4 py-sm-5">

            <div className="row d-flex align-items-center">

                {/* PAGE TITLE */}
                <div className="offset-sm-4 col-sm-4 col-12">
                    <div className="main-title text-center bg-grey">
                        Monitored IG Profiles
                    </div>
                </div>

                {/* NEW PROFILE BUTTON */}
                <div className="col-sm-4 col-12 d-sm-flex pt-4 pt-sm-0 justify-content-end">
                    <div className="title text-center">
                        <button className="btn bg-grey btn-small-outline-dandi" onClick={() => setPopupVisible(true)}>
                            Add new profile
                        </button>
                    </div>
                </div>
            </div>

            <div className="row mt-5">

                {/* PROFILE LIST */}
                {data !== null && Object.keys(data.profiles).map((key, index) => <div className="col-12 col-sm-4 col-md-6 mb-4">
                    <div className="dandi-card p-4">

                        <div className="dots-menu d-none d-sm-flex">
                            <div className="row d-flex justify-content-end">
                                <img src={dots_menu} />
                            </div>
                            {/* <div className="row dots-menu-on-hover shadow-sm">
                                <div className="dots-menu-on-hover-li">update data</div>
                                <div className="dots-menu-on-hover-li">close</div>
                            </div> */}
                        </div>

                        {/* Logo and profile name */}
                        <div className="row d-flex align-items-center">
                            <div className="col-auto">
                                <div className="logo">
                                    <FirebaseImage path={data.profiles[key].logo} />
                                </div>
                            </div>
                            <div className="col-auto title">
                                {key}
                            </div>
                        </div>

                        {/* Second row */}
                        <div className="row mt-3">
                            <div className="col-6 col-sm-4">
                                <div className="row">
                                    <div className="col-12 font-light">
                                        DandI score:
                                    </div>
                                    <div className="col-12 value">
                                        {data.profiles[key].score}%
                                    </div>
                                </div>
                            </div>

                            <div className="col-4">
                                <div className="row">
                                    <div className="col-12 font-light">
                                        Posts:
                                    </div>
                                    <div className="col-12 value">
                                        {data.profiles[key].posts}
                                    </div>
                                </div>
                            </div>

                            <div className="col-6 col-sm-4 mt-3 mt-sm-0">
                                <div className="row">
                                    <div className="col-12 font-light">
                                        Last scan:
                                    </div>
                                    <div className="col-12 value">
                                        {data.profiles[key].last_scan}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-12 text-center font-blue fw-bold">
                                <Link to={"/dashboard/" + key}>VIEW DASHBOARD</Link>
                            </div>
                        </div>

                    </div>
                </div>
                )}

            </div>

        </div>

    </BaseTemplate>
}