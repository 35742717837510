import "./style.css"
import logo from '../../assets/images/logo.png'
import benefits_1 from '../../assets/images/benefits-1.png'
import benefits_2 from '../../assets/images/benefits-2.png'
import benefits_3 from '../../assets/images/benefits-3.png'
import benefits_4 from '../../assets/images/benefits-4.png'
import home_image_1 from '../../assets/images/home_image_1.png'
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { useData } from "../../components/DataProvider/DataProvider"
import { Background } from "../../components/Background/Background"
import { Footer } from "../../components/Footer/Footer"

export function Home() {

    const navigate = useNavigate()
    const [loginVisible, setLoginVisible] = useState(false)
    const [demoPopupVisible, setDemoPopupVisible] = useState(false)
    const [loginClasses, setLoginClasses] = useState("")
    const [demoPopupClasses, setDemoPopupClasses] = useState("")
    const { data, updateData, isAuthenticated } = useData()

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    useEffect(() => {
        let base = "login-popup login flex-column justify-content-center align-items-center p-0"
        if (loginVisible === true) {
            base = base + " d-flex"
        } else {
            base = base + " d-none"
        }
        setLoginClasses(base)

    }, [loginVisible])

    useEffect(() => {
        let base = "demo-popup demo flex-column justify-content-center align-items-center p-0"
        if (demoPopupVisible === true) {
            base = base + " d-flex"
        } else {
            base = base + " d-none"
        }
        setDemoPopupClasses(base)

    }, [demoPopupVisible])

    async function login() {
        updateData("login", {
            "username": username,
            "password": password
        }, () => navigate("/dashboard"))
    }

    function handleLogin() {
        if (isAuthenticated === true)
            navigate("/dashboard")
        else
            setLoginVisible(true)
    }

    function handleDemoPopup() {
        setDemoPopupVisible(true)
    }

    return <div className="container-fluid home p-0">

        <Background />

        {/* LOGIN POPUP */}
        <div className={loginClasses}>

            <div className="w-100 h-100 overlay" onClick={() => setLoginVisible(false)}></div>

            <div className="row login-input p-5 rounded shadow">

                {/* Logo */}
                <div className="col-12 justify-content-center flex-row d-flex align-items-center">
                    <img src={logo} className="logo img-fluid" alt="logo" />
                </div>

                {/* Username */}
                <div className="col-12 mt-5">
                    <label className="w-100 text-center mb-2 font-blue fw-bold">USERNAME</label>
                    <input onChange={(e) => setUsername(e.target.value)} type="text" name="username" autoComplete="off" className="w-100 text-center" />
                </div>

                {/* Password */}
                <div className="col-12 mt-3">
                    <label className="w-100 text-center mb-2 font-blue fw-bold">PASSWORD</label>
                    <input onChange={(e) => setPassword(e.target.value)} type="password" name="username" autoComplete="off" className="w-100 text-center" />
                </div>

                {/* Login button */}
                <div className="col-12 mt-4">
                    <button onClick={() => login()} className="btn btn-dandi w-100">
                        Login
                    </button>
                </div>
            </div>
        </div>

        {/* BOOK A DEMO POPUP */}
        <div className={demoPopupClasses}>

            <div className="w-100 h-100 overlay" onClick={() => setDemoPopupVisible(false)}></div>

            <div className="row login-input p-5 rounded shadow">

                {/* Logo */}
                <div className="col-12 justify-content-center flex-row d-flex align-items-center">
                    <img src={logo} className="logo img-fluid" alt="logo" />
                </div>

                {/* Mail */}
                <div className="col-12 mt-5">
                    <label className="w-100 text-center mb-2 font-blue fw-bold">YOUR MAIL ADDRESS</label>
                    <input onChange={(e) => setUsername(e.target.value)} type="text" name="username" autoComplete="off" className="w-100 text-center" />
                </div>

                {/* Password */}
                <div className="col-12 mt-3">
                    <label className="w-100 text-center mb-2 font-blue fw-bold">YOUR REQUEST</label>
                    <textarea onChange={(e) => setPassword(e.target.value)} rows="8" name="username" autoComplete="off" className="w-100 text-start" />
                </div>

                {/* Login button */}
                <div className="col-12 mt-4">
                    <button onClick={() => setDemoPopupVisible(false)} className="btn btn-dandi w-100">
                        SEND REQUEST
                    </button>
                </div>

            </div>

        </div>

        <div className="container home-block-0">
            {/* HOME PAGE HEADER */}
            <div className="row home-header">
                <div className="col-12 col-md-2 mt-2 mt-sm-0 h-100 justify-content-center flex-row d-flex align-items-center">
                    <img className="bg-grey" src={logo} alt="logo" />
                </div>
                <div className="col-12 col-md-10 justify-content-end flex-row d-none d-sm-flex align-items-center d-none d-sm-none">
                    <div className="ps-4 menu-item d-flex flex-row align-items-center">FAQ</div>
                    <div className="ps-4 menu-item d-flex flex-row align-items-center">CONTACT US</div>
                    <div className="ps-4 menu-item d-flex flex-row align-items-center">BOOK A DEMO</div>
                </div>
            </div>
        </div>

        {/* FIRST BLOCK */}
        <div className="container home-block-1">
            <div className="row min-h-100 d-flex align-items-center flex-row">
                <div className="col-12 col-sm-7 col-xl-6 text-center text-sm-start mt-6 mt-sm-0">
                    <div className="row font-normal">
                        <div className="bg-grey col-12 title">
                            Our <span className="font-accent">AI</span> drives<br />your <span className="font-accent">visual communication</span><br />to <span className="font-accent">diversity</span> and <span className="font-accent">inclusion</span>
                        </div>
                    </div>
                    <div className="row font-normal mt-3">
                        <div className="bg-grey col-12 subtitle font-light">
                            We supply you with interactive dashboards <br />and actionable insights to be more respectful<br /> and balanced of world people identities
                        </div>
                    </div>
                    <div className="row font-normal mt-4 pb-5 pb-sm-0">
                        <div className="col-12 col-sm-4 pb-3 pb-sm-0 pe-sm-2">
                            <button onClick={() => handleDemoPopup()} className="btn btn-dandi w-100">
                                Book a demo
                            </button>
                        </div>
                        <div className="col-12 col-sm-4 ps-sm-2">
                            <button onClick={() => handleLogin()} className="bg-white btn btn-outline-dandi w-100">
                                Login
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col-sm-5 col-xl-6 d-none d-sm-block">
                    <img className="img-fluid" src={home_image_1} />
                </div>
            </div>
        </div>

        {/* SECOND BLOCK */}
        {/* <div className="container-fluid home-block-2 trailer px-0">
            <iframe src="https://www.youtube.com/embed/j4Z32fyxlt4" title="DandI Official Trailer" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div> */}

        {/* THIRD BLOCK */}
        <div className="container home-block-3 d-flex flex-column justify-content-center align-items-center d-none">

            <div className="row">
                <div className="col-12 title text-center">
                    Become more inclusive
                </div>
            </div>

            <div className="row mt-5">
                <div className="col-4">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center">
                            <img className="logo" src={benefits_1} />
                        </div>
                        <div className="col-12 text-center benefit-title">
                            Benefit of DandI
                        </div>
                        <div className="col-12 text-center benefit-text">
                            Lorem ipsum dolor sit amet,
                            consectetur adipiscing elit, sed do eiusmod tempor
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center">
                            <img className="logo" src={benefits_3} />
                        </div>
                        <div className="col-12 text-center benefit-title">
                            Benefit
                        </div>
                        <div className="col-12 text-center benefit-text">
                            Lorem ipsum dolor sit amet,
                            consectetur adipiscing elit, sed do eiusmod tempor
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center">
                            <img className="logo" src={benefits_2} />
                        </div>
                        <div className="col-12 text-center benefit-title">
                            Benefit
                        </div>
                        <div className="col-12 text-center benefit-text">
                            Lorem ipsum dolor sit amet,
                            consectetur adipiscing elit, sed do eiusmod tempor
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/* SIXTH BLOCK */}
        <div className="container-fluid home-block-3 home-block-6 d-flex flex-column justify-content-center align-items-center py-5">

            <div className="row">
                <div className="col-12 title text-center text-white">
                    Empower your brand visual communication
                </div>
            </div>

            <div className="row mt-5">
                <div className="col-12 col-sm-4">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center">
                            <img className="logo" src={benefits_4} />
                        </div>
                        <div className="col-12 text-center benefit-title text-white">
                            Simple
                        </div>
                        <div className="offset-2 col-8 text-center benefit-text text-white">
                            Zero touch configuration AI platform that requires only a few minutes to dive into analytics
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-4 mt-5 mt-sm-0">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center">
                            <img className="logo" src={benefits_3} />
                        </div>
                        <div className="col-12 text-center benefit-title text-white">
                            Flexible
                        </div>
                        <div className="offset-2 col-8 text-center benefit-text text-white">
                            Fully interactive dashboard  that enables you to build custom views and analyses
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-4 mt-5 mt-sm-0">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center">
                            <img className="logo" src={benefits_2} />
                        </div>
                        <div className="col-12 text-center benefit-title text-white">
                            Actionable
                        </div>
                        <div className="offset-2 col-8 text-center benefit-text text-white">
                            AI generated insights that allow to improve your visual communication
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/* FOURTH BLOCK */}
        <div className="container-fluid home-block-4 p-0">
            <div className="col-12 mb-5 title text-center">
                Start <span className="text-white">dandi</span>ng
            </div>
            <div className="px-3 px-sm-0 col-12 offset-sm-3 col-sm-6 mb-5 text text-center">
                If you are interested of danding, please ask a demo by filling your data
            </div>
            <div className="col-10 offset-1 offset-sm-4 col-sm-4 text text-center request-a-demo">
                <button className="btn btn-outline-dandi w-100">
                    Request a demo
                </button>
            </div>
        </div>

        {/* FOOTER */}
        <Footer />

    </div>
}