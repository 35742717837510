

export function PrivacyPoloicy() {

    return <div className="container py-5">

        <div className="row">

            <div className="col-12 text-center">
                <h1>Privacy Policy and Cookie Policy</h1>
            </div>

            <div className="col-12 mt-4">
                This policy describes the procedures followed by DandI.ai in relation to the processing of personal data collected through this website or its subsidiaries (hereinafter the "Website").
                <br />
                Unless otherwise stated, this policy also applies as an information notice – in accordance with art. 13 of Regulation (EU) no. 2016/679 (hereinafter the "GDPR") – rendered to those who interact with the Website (hereinafter the "User").
                <br />
                Information on the processing of detailed personal data is provided, where necessary, in the pages relating to the individual services offered through the Website. Such information is aimed at defining the limits and methods of processing the personal data of each service, on the basis of which the User can freely express his consent, where necessary, and possibly authorize the collection of data and their subsequent processing.
            </div>

            <div className="col-12 mt-4">
                <h5>Data Controller. Data Processors.</h5>
                The Data Controller is DandI.ai with e-mail address admin@dandi.ai. The updated list of any data processors is available at the registered office of the Controller.
            </div>

            <div className="col-12 mt-4">
                <h5>Data Protection Officer.</h5>
                The Data Protection Officer appointed by the Controller can be contacted by:
                <ul>
                    <li>e-mail address admin@dandi.ai.</li>
                </ul>
            </div>

            <div className="col-12 mt-2">
                <h5>Types of data processed.</h5>
                Cookies are small text files that websites you visit send to your terminal, where they are stored, and then retransmit to the same websites the next time you visit. Cookies allow websites to function properly and efficiently to improve your experience by allowing the website to store information in the memory of your computer or other devices.
                <br />
                The Website uses technical cookies. Such cookies, due to the technical nature, do not require the prior consent of the User to be installed and used.
                <br />
                In particular, the cookies used on the Website are traceable to the following subcategories:
                <ul>
                    <li>browsing or session cookies, which ensure normal browsing and use of the Website and to collect anonymous information about how users use the website and how many visitors has the website, where they come from, and the other websites they have visited. As they are not stored on the user's computer, they disappear when the browser is closed;</li>
                    <li>analytical cookies, such as, for example, those used by Google Analytics, with which statistical information are collected and analyzed, through the computer and other devices, on the number of users of the website, or on the number of clicks on the page during their navigation, or from which website users come and the pages they have visited;</li>
                </ul>
                Our site does not use third-party cookies; our site does not use profiling cookies to provide targeted advertising to users.<br />
                The third party cookies installed on the Website are listed below. For each of them, there is a link to the relative information notice on the processing of personal data carried out and the methods for deactivation of any cookies used. With regard to third party cookies, the Controller is only obliged to insert the link to the third party's website in this policy. On the other hand, the latter is required to provide information and indicate how to consent to and/or deactivate cookies.<br />
                Google Analytics: https://support.google.com/analytics/answer/6004245 <br />
                Twitter, Facebook, LinkedIn, YouTube
            </div>

            <div className="col-12 mt-4">
                <h5>Purpose and legal basis of the processing.</h5>
                Personal data collected through the Website will be processed:
                <ul>
                    <li>for the management of requests for information submitted by the User.</li>
                </ul>
                The processing of personal data for the purposes under a) does not require the consent of the User as the processing is necessary to meet specific requests of the subject concerned under art. 6, par. 1, lett. b) of the GDPR.
            </div>

            <div className="col-12 mt-4">
                <h5>Purpose and legal basis of the processing.</h5>
                The provision of personal data for the purposes under a) is optional and failure to provide them shall result, as a consequence, the only impossibility for the Data Controller to manage and process requests or to send commercial communications on products and services of the same or to carry out the activities specified.
            </div>

            <div className="col-12 mt-4">
                <h5>Recipients or categories of recipients.</h5>
                Personal data may be made accessible, brought to the attention of or communicated to the following subjects, who will be appointed by, depending on the case, as processors or authorized persons:
                <ul>
                    <li>companies of the group to which the Controller belongs (parent companies, subsidiaries, affiliated), employees and/or collaborators in any capacity of the Controller and/or companies in the group to which the Controller belongs;</li>
                    <li>public or private entities, natural or legal persons, which the Controller uses to carry out activities instrumental to achieving the above mentioned purpose or to which the Controller is required to communicate personal data, by virtue of legal or contractual obligations;</li>
                </ul>
                In any case, personal data will not be disclosed.
            </div>

            <div className="col-12 mt-4">
                <h5>Storage period.</h5>
                The data shall be stored for a maximum period of time equal to the period of prescription of the rights that can be activated by the Controller, as applicable from time to time.
            </div>

            <div className="col-12 mt-4">
                <h5>Rights of access, deletion, restriction and data portability.</h5>
                Concerned subjects are entitled to the rights set out in art. 15 to 20 of the GDPR. By way of example, any concerned subject may:<br />
                (a) obtain confirmation as to whether or not personal data relating to him are being processed;(b) where processing is ongoing, to obtain access to personal data and information relating to the processing and to request a copy of the personal data;(c) rectify inaccurate personal data and integrate incomplete personal data;(d) to obtain, where one of the conditions laid down in art. 17 of GDPR are satisfied, the deletion of personal data concerning him;(e) to obtain, in the cases provided for in art. 18 of the GDPR, the restriction of processing;(f) receive their personal data in a structured format that is commonly used and machine-readable, and request their transmission to another controller if technically feasible.
            </div>

            <div className="col-12 mt-4">
                <h5>Right to revoke the consent.</h5>
                In the event that consent is required for the processing of personal data, each interested party may, at any time, revoke the consent already given, without prejudice to the lawfulness of the processing based on consent given before the revocation.
            </div>

            <div className="col-12 mt-4">
                <h5>Right to complain to the Supervisory Authority.</h5>
                In addition, any concerned subject may lodge a complaint with the Italian Data Protection Authority in the event that he/she believes that his/her rights under the GDPR have been violated, in accordance with the procedures indicated on the Italian Data Protection Authority’s website accessible at: www.garanteprivacy.it.
            </div>

            <div className="col-12 mt-4">
                <h5>Updates.</h5>
                This Privacy Policy will be subject to updates. The Controller therefore invites Users who wish to know how personal data collected through the Website will be processed to periodically visit this page.
            </div>

        </div>

    </div>

}