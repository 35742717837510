import "./style.css"
import done from '../../assets/images/checked.png'
import { useSearchParams } from "react-router-dom"
import { useEffect, useState } from "react"
import axios from "axios"
import { BaseTemplate } from "../../components/BaseTemplate"

export function Activate() {

    const [params, setSearchParams] = useSearchParams();
    const [success, setSuccess] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            let username = params.get("username")
            let activation_code = params.get("activation_code")

            if (username && activation_code && username !== null && activation_code !== null) {
                console.log(username, activation_code)
                axios.post("https://api.dandi.digital/activate", { username: username, activation_code: activation_code }).then((response) => {
                    setSuccess(true)
                    console.log(response)
                })
            }
        }, 5000)
    }, [])

    return <BaseTemplate header={true} center={true}>

        {(success === true) && <div className="container home-block-0 justify-content-center d-flex">
            <div className="form w-100">
                <div className="input-container row mt-4 text-center d-flex justify-content-center">
                    <div className="text-center">Nice to see you {params.get("username")} !</div>
                    <img className="p-0 m-0 mt-5" style={{ width: "50px", height: "50px" }} src={done} alt="logo" />
                    <div className="text-center text-success fw-bold mt-3">Your profile is now active!</div>
                </div>
            </div>
        </div>}

        {(success === false) && <div className="container home-block-0 justify-content-center d-flex">
            <div className="form w-100">
                <div className="input-container row mt-4 text-center d-flex justify-content-center">
                    <div className="text-center">Nice to see you {params.get("username")} !</div>
                    <div className="spinner-border text-warning mt-5" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    <div className="text-center mt-3 text-warning fw-bold">We are activating your profile...</div>
                </div>
            </div>
        </div>}

    </BaseTemplate>
}