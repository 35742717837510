import { Link } from "react-router-dom";
import './style.css'

export function Footer() {

    return <div className="container p-5 home-block-5 footer d-flex justify-content-center align-items-center">
        <div className="row">
            <div className="col-12 text-center">
                © 2022 – DandI.ai
            </div>
            <div className="col-12 text-center mt-3">
                <Link to={"/privacy-policy"}>Privacy Policy and Coocie Policy</Link>
            </div>
        </div>
    </div>

}