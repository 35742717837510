import { createContext, useContext, useEffect, useState } from "react"
import { getFirestore, getDoc, doc, setDoc, getDocs, collection, where, query } from "firebase/firestore"
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
var md5 = require('md5');

// Initialize the context
const DataContext = createContext(null);

// Create Data context provider
export function DataContextProvider({ children, app }) {

    // Initialize the database
    const db = getFirestore(app)
    const [data, setData] = useState(null);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        if (data === null && loading === true)
            download()

        // Execute update
        update()

    }, [data])

    async function download() {
        let newData = {
            "profiles": {}
        }
        
        // Load all active profiles
        let querySnapshot = await getDocs(query(collection(db, "profiles"), where("visible", "==", true)));

        querySnapshot.forEach((doc) => {
            newData["profiles"][doc.id] = doc.data()
        });

        setData(newData)
    }

    function update() {

        // Read values from the local storage
        var token = localStorage.getItem("token")

        var user = null
        if (token !== null) {
            user = {
                "token": token
            }
        }

        console.log("user", user)

        setUser(user)
        setLoading(false)
    }

    async function login(payload, callback) {

        try {

            // Get username and password from the bundle
            let username = payload['username']
            let password = payload['password']

            axios.post("https://api.dandi.digital/login", {
                "username": username,
                "password": password
            }).then((response) => {
                
                // Get token
                let token = response.data['token']

                // Save token to local storage
                localStorage.setItem("token", token)

                // Save token to local storage
                localStorage.setItem("username", username)

                update()

                callback()

            }).catch((error) => {
                console.log(error)
            })

        } catch (e) {
            console.error(e)
            return false
        }

        return true
    }

    async function addNewProfile(payload, callback) {
        
        // Get username of the requested profile
        let username = payload['username']

        // Create document
        let document = {
            "last_scan": "running",
            "logo": "280133234_714444373029327_1816612216657032107_n.jpg",
            "name": username,
            "posts": 0,
            "visible": true
        }

        await setDoc(doc(db, "profiles", username), document)

        // Update local data
        let newData = {...data}
        newData['profiles'][username] = document
        setData(newData)

        callback()

    }

    function updateData(op, data, callback) {
        console.log(op, data)
        switch (op) {

            /**
             * Perform the login. Data must contain a "username" and a "password".
             */
            case "login":
                login(data, callback);
                break;

            case "addNewProfile":
                addNewProfile(data, callback)
                break;
        }
    }

    function update() {

        // Read values from the local storage
        var token = localStorage.getItem("token")
        var username = localStorage.getItem("username")

        var user = null
        if (token !== null) {
            user = {
                "token": token,
                "username": username
            }
        }

        console.log("user", user)

        setUser(user)
        setLoading(false)
    }

    return (
        <DataContext.Provider value={{ data, user, updateData }}>
            {!loading && children}
        </DataContext.Provider>
    )

}

// Function to determine the auth state
export function useData() {
    const { data, user, updateData } = useContext(DataContext);
    return { data: data, updateData: updateData, user: user, isAuthenticated: user !== null };
}