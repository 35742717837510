import './App.css';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Home } from './pages/home/index';
import { initializeApp } from 'firebase/app';
import { getStorage, ref } from "firebase/storage";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { DataContextProvider, useData } from './components/DataProvider/DataProvider';
import { Dashboard } from './pages/dashboard';
import { ProfileDashboard } from './pages/profile_dashboard';
import { PrivacyPoloicy } from './pages/privacy-policy/PrivacyPolicy';
import { Subscribe } from './pages/subscribe';
import { Activate } from './pages/activate';
import { AddNewProfile } from './pages/add_new_profile';

const firebaseConfig = {
  apiKey: "AIzaSyB1Q398x_vMpdqZMA_mscNjwEJ8sPeNhvo",
  authDomain: "dandi-349014.firebaseapp.com",
  projectId: "dandi-349014",
  storageBucket: "dandi-349014.appspot.com",
  messagingSenderId: "1044605771785",
  appId: "1:1044605771785:web:28bc4badf5eb5a3e6198d8",
  measurementId: "G-BFNE2DBE45"
};

const app = initializeApp(firebaseConfig)

const auth = getAuth(app);
signInWithEmailAndPassword(auth, "admin@dandi.ai", "cola1000")
  .then((userCredential) => {
    // Signed in 
    const user = userCredential.user;
    console.log(userCredential)
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log(error)
  });

// Create a reference with an initial file path and name
export const storage = getStorage(app)

// The private route checks if the user is currently logged and if so return the
// children otherwise redirect to the login page
export const PrivateRoute = ({ children }) => {

  // Call useAuth to know if the user is authenticated
  let { isAuthenticated } = useData()

  console.log("Logged in:", isAuthenticated)

  // If-else with the redirect
  return isAuthenticated === true ? children : <Navigate to="/" />

}

function App() {
  return <DataContextProvider app={app}>
    <BrowserRouter>
      <Routes>

        {/* Landing page */}
        <Route path="/" element={<Home />} />

        {/* Subscribe page */}
        <Route path="/subscribe/" element={<Subscribe />} />

        {/* Activation page */}
        <Route path="/activate/" element={<Activate />} />

        {/* Add new profile */}
        <Route path="/new_profile/" element={<AddNewProfile />} />

        {/* Dashboard page */}
        <Route path="/dashboard/" element={<PrivateRoute><Dashboard /></PrivateRoute>} />

        {/* Dashboard page for a specific instagram page */}
        <Route path="/dashboard/:profile_id" element={<PrivateRoute><ProfileDashboard /></PrivateRoute>} />

        {/* Code of ethics */}
        <Route path="/privacy-policy" element={<PrivacyPoloicy />} />

      </Routes>
    </BrowserRouter>
  </DataContextProvider>
}

export default App;
