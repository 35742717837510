import { useEffect, useState } from "react"
import "./style.css"

// Create auth context provider
export function AgePlotBar({ label, amount, color }) {

    const classes = "col ps-0 text-end highlighted" + " font-" + color

    return <div className="col-12 mb-3">
        <div className="row age-bar d-flex align-items-center">
            <div className="col-auto h-100 age-bar-label">{label}</div>
            <div className={classes}>{amount}%</div>
        </div>
        <div className="row">
            <div className="col-12 age-bar-bar mt-2">
                <div className="age-bar-background px-0"></div>
                <div className={"h-100 age-bar-item bg-" + color} style={{width: amount + '%'}}></div>
            </div>
        </div>
    </div>

}