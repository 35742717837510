import { useLayoutEffect, useRef } from "react"
import { useEffect, useState } from "react"
import "./style.css"

// Create auth context provider
export function StackedPlotBar({ label, items, width, amount1, amount2, color1, color2 }) {

    const [a1, setA1] = useState(amount1 > 0 ? amount1 : 4)
    const [a2, setA2] = useState(amount2 > 0 ? amount2 : 4)
    const [parent, setParent] = useState(width)
    const [parentWidth, setParentWidth] = useState(15)

    useEffect(() => {
        if (parent !== null && parent.current !== null && parent.current.clientWidth !== null) {
            setParentWidth(Math.floor(parent.current.clientWidth / items))
            console.log(parent.current.clientWidth, items)
        }
    }, [parent])

    return parentWidth !== null && <div style={{ width: parentWidth }} className="col-auto px-0 d-flex justify-content-start flex-column align-items-center">
        <div className="vertical-bar d-flex flex-column justify-content-end" style={{ width: parentWidth * 0.7 }}>
            <div className={"label azzurro"}>{amount1}%</div>
            <div className={"vertical-bar-upper"} style={{ height: a1 + '%' }}></div>
            <div className={"vertical-bar-lower"} style={{ height: a2 + '%' }}></div>
            <div className={"label pink"}>{amount2}%</div>
        </div>
        <div className="vertical-bar-label mt-3">
            {label}
        </div>
    </div>

}