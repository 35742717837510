import { getDownloadURL, ref } from 'firebase/storage';
import { useEffect, useState } from 'react';
import { storage } from '../../App';
import './style.css';

export function FirebaseImage({ path, horizontal=true }) {

    const [url, setUrl] = useState(null)
    const classes = horizontal === true ? "post-image-horizontal" : "post-image-vertical"

    useEffect(() => {
        getDownloadURL(ref(storage, "gs://dandi-349014.appspot.com/" + path)).then((u) => {
            setUrl(u)
        })
    }, [])

    return <div className='w-100 post-image'>
        {url !== null && <img className={classes} src={url} />}
        {url === null && <>Loading...</>}
    </div>

}