import "./style.css"
import cry from '../../assets/images/crying.png'
import rocket from '../../assets/images/rocket.png'
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { useData } from "../../components/DataProvider/DataProvider"
import { Background } from "../../components/Background/Background"
import { Footer } from "../../components/Footer/Footer"
import axios from "axios"
import { BaseTemplate } from "../../components/BaseTemplate"

export function Subscribe() {

    const navigate = useNavigate()
    const [loginVisible, setLoginVisible] = useState(false)
    const [demoPopupVisible, setDemoPopupVisible] = useState(false)
    const [loginClasses, setLoginClasses] = useState("")
    const [demoPopupClasses, setDemoPopupClasses] = useState("")
    const { data, updateData, isAuthenticated } = useData()

    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [loading, setLoading] = useState(false)

    function subscribe() {
        setLoading(true)
        axios.post("https://api.dandi.digital/subscribe", {
            "username": username,
            "password": password
        }).then((response) => {
            setSuccess(true)
            console.log(response)
        }).catch((error) => {
            setError(true)
            console.log(error)
        })
    }

    return <BaseTemplate header={true} center={true}>

        {(loading===false && success === false && error === false) && <div className="container home-block-0 justify-content-center d-flex">
            <div className="form w-50">
                <div className="input-container row mt-4">
                    <span className="mt-3 mx-0 px-0">The DandI platform is currently in beta. Please fill the form below to request access.</span>
                    <span className="mt-3 mx-0 px-0">You will receive an email to activate your account.</span>
                </div>
                <div className="input-container row mt-4">
                    <label>Username </label>
                    <input onChange={(e) => setUsername(e.target.value)} type="text" name="uname" required />
                </div>
                <div className="input-container row mt-4">
                    <label>Password </label>
                    <input onChange={(e) => setPassword(e.target.value)} type="password" name="pass" required />
                </div>
                <div className="button-container row mt-4">
                    <input onClick={() => subscribe()} type="submit" />
                </div>
            </div>
        </div>}

        {success == true && <div className="container home-block-0 justify-content-center d-flex">
            <div className="form w-50">
                <div className="input-container row mt-4 text-center d-flex justify-content-center">
                    <img className="p-0 m-0" style={{ width: "50px", height: "50px" }} src={rocket} alt="logo" />
                    <span className="mt-3 mx-0 px-0">Thanks {username}!</span>
                    <span className="mt-3 mx-0 px-0">Please check out your mail inbox to activate your account.</span>
                </div>
            </div>
        </div>}

        {error == true && <div className="container home-block-0 justify-content-center d-flex">
            <div className="form w-50">
                <div className="input-container row mt-4 text-center d-flex justify-content-center">
                    <img className="p-0 m-0" style={{ width: "50px", height: "50px" }} src={cry} alt="logo" />
                    <span className="mt-3 mx-0 px-0">Something went wrong!</span>
                    <span className="mt-3 mx-0 px-0">Please contact the administrator or try again.</span>
                </div>
            </div>
        </div>}

        {(loading === true && success === false && error === false) && <div className="container home-block-0 justify-content-center d-flex">
            <div className="form w-50">
                <div className="input-container row mt-4 text-center d-flex justify-content-center">
                    <div className="spinner-border text-dark" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        </div>}

    </BaseTemplate>
}