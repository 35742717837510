import { Background } from "../Background/Background";
import { Footer } from "../Footer/Footer";
import { Header } from "../Header/Header";

export function BaseTemplate({ children, header = true, center = false }) {

    return <div className="container-fluid p-0 m-0 wrapper-body">

        {/* BACKGROUND */}
        <Background />

        {header === true && <Header />}

        <div className={"body m-0 p-0" + (center === true ? " d-flex justify-content-center align-items-center" : "")}>
            {children}
        </div>

        <Footer />

    </div>

}